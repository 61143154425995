// Main.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import AdsenseContent from './AdsenseContent';
import AdsenseContentInter from './AdsenseContent2';

import Footer from './Footer';
import quizData from './quiz_quuestions.json';
import coin from '../images/coin.png';
import close_icon from '../images/close_icon.svg';
import trophy from '../images/cup.svg';
import winner from '../images/winner.gif';

const Main = () => {
  const navigate = useNavigate();

  const [activeCategory, setActiveCategory] = useState('world');
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [coinsEarned, setCoinsEarned] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupClaimed, setPopupClaimed] = useState(false);

  useEffect(() => {
    const categoryQuestions = quizData.data?.[activeCategory]?.religions?.slice(0, 2) || [];
    setQuestions(categoryQuestions);
  }, [activeCategory]);

  useEffect(() => {
    Cookies.set('coinsEarned', coinsEarned, { expires: 7 });
  }, [coinsEarned]);

  const handlePlayQuizClick = (event) => {
    event.preventDefault();
    navigate('/Category');
  };

  const handleAnswerSelection = (selectedOption, correctAnswer) => {
    setSelectedAnswer(selectedOption);
    setAnswered(true);
    if (selectedOption === correctAnswer) {
      setCorrectCount(prevCount => prevCount + 1);
      setCoinsEarned(prevCoins => prevCoins + parseInt(questions[currentQuestionIndex]?.coins || 0, 10));
    } else {
      setWrongCount(prevCount => prevCount + 1);
    }
    if (currentQuestionIndex === questions.length - 1) {
      setShowPopup(true);
    } else {
      setTimeout(handleNextQuestion, 1000);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      setAnswered(false);
      setSelectedAnswer(null);
    } else {
      handleQuizCompletion();
    }
  };

  const handleClaimReward = () => {
    setPopupClaimed(true);
    setShowPopup(false);
    setCoinsEarned(prevCoins => prevCoins + 100);
    handleNextQuestion();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    handleNextQuestion();
  };

  const handleQuizCompletion = () => {
    Cookies.set('coinsEarned', coinsEarned, { expires: 7 });
    setQuizCompleted(true);
  };

  const renderPopup = () => (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="atmequiz_game_cato_que_game_about txt_center">
          <img className="flip_image" src={coin} alt="coin-icon" />
        </div>
        <h2>New Reward Available</h2>
        <p>Get Instant 100 Coins!</p>
        <p>Watch a simple ad and get rewarded</p>
        <button className="claim-button atmequiz_game_quiz_cato_join_btn primary_button" onClick={handleClaimReward}>
          Claim
        </button>
        <button className="close-button" onClick={handleClosePopup}>
          <img src={close_icon} alt="Close" />
        </button>
      </div>
    </div>
  );

  const renderQuizContent = () => {
    if (quizCompleted) {
      return (
        <div className="quiz-completed">
          <div className="atmequiz_game_played_score_box txt_center flex_align_center js_center">
            <div className="atmequiz_game_played_score_img">
              <img src={winner} alt="winner" className="winner_trophy2" />
              <img src={trophy} alt="trophy" />
            </div>
            <div className="atmequiz_game_played_score_content_box">
              <h2 className="atmequiz_game_played_score_text">
                Your score is:
                <span className="atmequiz_game_played_score_number span_section_position">
                  {coinsEarned}{' '}
                </span>
              </h2>
              <Link to="/Category" className="buttonBG">
                <button className="atmequiz_game_quiz_cato_join_btn primary_button">
                  Play Quiz
                </button>
              </Link>
            </div>
          </div>
        </div>
      );
    }

    if (showPopup && !popupClaimed) {
      return renderPopup();
    }

    if (questions.length > 0 && currentQuestionIndex < questions.length) {
      const { question, answer, correct } = questions[currentQuestionIndex];
      return (
        <div className="atmequiz_game_cato_que_content_box">
          <div className="atmequiz_game_que_page_card">
            <div className="atmequiz_game_que_card_body txt_center">
              <div className="atmequiz_game_que_card_content_wrap">
                <div className="atmequiz_game_quiz_card_que_number flex_align_center js_center">
                  <p className="atmequiz_game_que_card_number_text">
                    Question{' '}
                    <span className="atmequiz_game_que_card_curr_que_num">
                      {currentQuestionIndex + 1}
                    </span>{' '}
                    /{' '}
                    <span className="atmequiz_game_que_card_total_que_num">
                      {questions.length}
                    </span>
                  </p>
                </div>
                <h4 className="atmequiz_game_quize_card_que_title">{question}</h4>
                <div className="atmequiz_game_option_box">
                  <ul className="atmequiz_game_option_list">
                    {answer.map((option, index) => (
                      <li key={index} className="flex_align_center js_center txt_center">
                        <button
                          onClick={() => handleAnswerSelection(option, correct)}
                          className={`atmequiz_option_list_item flex_align_center js_center`}
                          style={{
                            backgroundColor: !answered
                              ? selectedAnswer === option
                                ? option === correct
                                  ? '#92db92'
                                  : '#f35757'
                                : ''
                              : option === correct
                              ? '#92db92'
                              : selectedAnswer === option && option !== correct
                              ? '#f35757'
                              : '',
                          }}
                          disabled={answered}
                        >
                          {option}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
               
                <AdsenseContent
                      adUnit='/23090897374/q11_d1/q11_d3'
                      name='div-gpt-ad-1726305927732-0'
                      size={[[250, 250], [300, 250], [336, 280]]}
                    />
                  <div className="atmequiz_game_contact_grid">
                    <h2 className="atmequiz_game_contact_section_title">
                      Quick Start!
                    </h2>
                    <p>
                      Answer {questions.length} questions and win up to{' '}
                      {questions.reduce(
                        (totalCoins, q) => totalCoins + parseInt(q.coins, 10),
                        0
                      )}{' '}
                      coins.
                    </p>
                    {renderQuizContent()}
                    <div className="atmequiz_game_quiz_cato_detail_box">
                      <div className="atmequiz_game_quiz_cato_detail_item">
                        <h6>#Fun Fact</h6>
                        <p>
                          It is impossible for most people to lick their own
                          elbow. (try it!)
                        </p>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
