import React, { useEffect } from "react";
import logo from '../images/logo.png';
import heart_icon from '../images/heart-icon.svg';
import india_flag_icon from '../images/india-flag-icon.svg';

export default function Footer() {
 
  return (
    <div className="atmequiz_game_footer">
      <div className="atmequiz_game_footer_inner">
        <div className="atmequiz_game_page_container">
           
          <div className="atmequiz_game_footer_wrap">
            <div className="atmequiz_game_footer_logo">
              <img src={logo} alt="logo"/>
            </div>
            <div className="atmequiz_game_footer_copiright_content">
              <div className="atmequiz_game_footer_copiright_text">
                Made with 
                <img src={heart_icon} alt="heart-icon"/>
                in India
                <img src={india_flag_icon} alt="india-flag-icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}