import React, { useEffect } from 'react';
import { useLocation, useNavigate,Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import trophy from '../images/cup.svg';
import winner from '../images/winner.gif';
import coin_icon from '../images/coin-icon.png';
import Footer from "./Footer";
import AdsenseContent from './AdsenseContent';

export default function Scoreboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { correctCount = 0, wrongCount = 0, redirectedFromPlayQuiz = false } = location.state || {};

  // Calculate the score based on correctCount
  const score = (correctCount * 100) / 10;

  // Calculate coins to be added
  const coinsToAdd = correctCount * 100;

  // Retrieve the total coins from cookies
  const currentCoins = parseInt(Cookies.get('coinsEarned') || '0', 10);
  
  // Calculate new total coins
  const newTotalCoins = currentCoins + coinsToAdd;

  useEffect(() => {
    // Update the cookie with the new total coins
    Cookies.set('coinsEarned', newTotalCoins, { expires: 7 });
  }, [newTotalCoins]);

  const handleGoToHomeClick = () => {
    navigate('/Category'); // Redirect to the Category route
  };

  return (
    <div className="atmequiz_game_page_body">
      <div className="atmequiz_game_page_main">
        <div className="atmequiz_game_cato_que_page_wrap">
        <AdsenseContent
                      adUnit='/23090897374/q11_d1/q11_d5'
                      name='div-gpt-ad-1726306032730-0'
                      size={[[336, 280], [250, 250], [300, 250]]}
                    />
          <div className="atmequiz_game_quiz_cato_detail_box2">
            <div className="atmequiz_game_quiz_cato_detail_item2">
            
              <div
                className="atmequiz_game_played_score_wrapper"
                style={{ display: redirectedFromPlayQuiz ? 'none' : 'block' }}
              >
                <div className="atmequiz_game_played_score_box txt_center flex_align_center js_center">
                  <div className="atmequiz_game_played_score_img">
                    <img src={trophy} alt="trophy" />
                    <img src={winner} alt="winner trophy" className="winner_trophy" />
                  </div>
                  <div className="atmequiz_game_played_score_content_box">
                    <span className="atmequiz_game_played_score_time_over_text">
                      Time is over! Well Played
                    </span>
                    <h2 className="atmequiz_game_played_score_text">
                      Your score is:
                      <span className="atmequiz_game_played_score_number span_section_position">
                        {score}
                      </span>
                    </h2>
                    <p className="atmequiz_game_played_score_content_desc">
                      Winner announcement will be @ 12:15 pm
                    </p>
                    <p className="atmequiz_game_played_score_content_desc">
                      Based on your current score, you can win
                      <span className="atmequiz_game_played_score_win_coin_text">
                        <strong> {coinsToAdd}</strong>
                      </span>
                      <span className="atmequiz_game_played_score_coin_img">
                        <img src={coin_icon} className="icon_postion" alt="coin-icon" />
                      </span>
                    </p>
                    <p className="atmequiz_game_played_score_content_desc">
                      Your total coins:
                      <span className="atmequiz_game_played_score_number span_section_position">
                        {newTotalCoins}
                      </span>
                      <span className="atmequiz_game_played_score_coin_img">
                        <img src={coin_icon} className="icon_postion" alt="coin-icon" />
                      </span>
                    </p>
                    <Link to="/Category" className="buttonBG">
                      <button className="atmequiz_game_quiz_cato_join_btn primary_button">
                      Go To Home
                      </button>
                    </Link>
                  </div>
                  <div className="atmequiz_game_played_score_info_wrap">
                    <ul className="atmequiz_game_played_score_info_box flex_align_center">
                      <li>
                        <div className="atmequiz_game_played_score_info_item price_sections">
                          <h4 className="atmequiz_game_played_score_info_number">1</h4>
                          <p className="atmequiz_game_played_score_info_subtitle">Current Rank</p>
                        </div>
                      </li>
                      <li>
                        <div className="atmequiz_game_played_score_info_item price_sections">
                          <h4 className="atmequiz_game_played_score_info_number">10</h4>
                          <p className="atmequiz_game_played_score_info_subtitle">Total Questions</p>
                        </div>
                      </li>
                      <li>
                        <div className="atmequiz_game_played_score_info_item price_sections">
                          <h4 className="atmequiz_game_played_score_info_number">{correctCount}</h4>
                          <p className="atmequiz_game_played_score_info_subtitle">Correct Answer</p>
                        </div>
                      </li>
                      <li>
                        <div className="atmequiz_game_played_score_info_item price_sections">
                          <h4 className="atmequiz_game_played_score_info_number">{wrongCount}</h4>
                          <p className="atmequiz_game_played_score_info_subtitle">Wrong Answer</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdsenseContent
                     
                    />
                            <Footer />
      </div>
    </div>
  );
}
